/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    h3: "h3",
    span: "span",
    ul: "ul",
    li: "li",
    strong: "strong",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "about-fleeyo"
  }, "About Fleeyo"), "\n", React.createElement(_components.p, null, "Fleeyo is a leading airport transfer taxi service provider based in Cambridge, United Kingdom."), "\n", React.createElement(_components.p, null, "Fleeyo specializes in offering seamless transfers to and from top London airports like Stansted, Luton, Heathrow, Gatwick, and London City. It takes pride in offering reliable and punctual services."), "\n", React.createElement(_components.p, null, "Their mission is to make airport travel comfortable and stress-free for their passengers and ensure they reach their destination on time, every time."), "\n", React.createElement(_components.p, null, "However, they were facing a few critical challenges that were holding them back from reaching their full potential."), "\n", React.createElement(_components.h2, {
    id: "challenges"
  }, "Challenges:"), "\n", React.createElement(_components.p, null, "Running a successful airport transfer service isn’t just about having cars and drivers—it’s about efficiency. For Fleeyo, there were 3 major challenges and the lack of digital solutions was a massive roadblock."), "\n", React.createElement(_components.h3, null, "No system to maintain records"), "\n", React.createElement(_components.p, null, "Fleeyo was running all its operations manually. There was no digital platform to manage bookings or keep track of records."), "\n", React.createElement(_components.p, null, "Every booking was written down by hand which led to disorganized paperwork and confusion."), "\n", React.createElement(_components.p, null, "This made it impossible to properly track customer data or the company’s performance over time."), "\n", React.createElement(_components.h3, null, "Difficulty in tracking driver availability"), "\n", React.createElement(_components.p, null, "Assigning rides to drivers was a manual and time-consuming process. Fleeyo’s team had to call each driver to check their availability and confirm the ride."), "\n", React.createElement(_components.p, null, "If the driver wasn’t available, the process repeated with other drivers."), "\n", React.createElement(_components.p, null, "This manual system led to delayed bookings and unsatisfied customers."), "\n", React.createElement(_components.h3, null, "No digital booking platform"), "\n", React.createElement(_components.p, null, "In today’s digital world, Fleeyo’s passengers couldn’t book a ride from anywhere."), "\n", React.createElement(_components.p, null, "The company had no online presence which limited its reach to local customers only."), "\n", React.createElement(_components.p, null, "International passengers or those outside Cambridge had no way to book rides online. This resulted in missed business opportunities and frustrated customers."), "\n", React.createElement(_components.h2, {
    id: "here-is-how-yelowsoft-helped"
  }, "Here is how Yelowsoft helped"), "\n", React.createElement(_components.p, null, "When Fleeyo came to Yelowsoft, we knew the solution had to address all these issues while giving them the tools to scale and grow."), "\n", React.createElement(_components.h3, null, "Integrated web booking platform for global presence"), "\n", React.createElement(_components.p, null, "The first step we did was to integrate a web booking platform into its existing website for Fleeyo."), "\n", React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 800px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 62.5%; position: relative; bottom: 0; left: 0; background-image: url('data:image/webp;base64,UklGRmgAAABXRUJQVlA4IFwAAADQAwCdASoUAA0APtFWpUwoJKOiMAgBABoJaQAAW+jKDY2SN+97IQAA/t411C/nln4CFaymAHI9Ty9kKBQOBrwrPS2hK3y+dvvPQOnPh78Bu/xkK9jUkP78kJxAAA=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"Integrated-web-booking-platform-for-global-presence\"\n        title=\"\"\n        data-src=\"/static/f37e5b808f4a88565e189cf49ab1da26/cf465/Integrated-web-booking-platform-for-global-presence.webp\"\n        data-srcset=\"/static/f37e5b808f4a88565e189cf49ab1da26/a5e6d/Integrated-web-booking-platform-for-global-presence.webp 200w,\n/static/f37e5b808f4a88565e189cf49ab1da26/2276a/Integrated-web-booking-platform-for-global-presence.webp 400w,\n/static/f37e5b808f4a88565e189cf49ab1da26/cf465/Integrated-web-booking-platform-for-global-presence.webp 800w\"\n        sizes=\"(max-width: 800px) 100vw, 800px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }), "\n", React.createElement(_components.p, null, "We helped Fleeyo turn a website into a web booking platform. Now, Fleeyo’s customers can book their airport transfers from anywhere in the world—whether they’re in Cambridge or across the globe."), "\n", React.createElement(_components.p, null, "Fleeyo now is able to receive instant booking notifications the moment a passenger books a ride."), "\n", React.createElement(_components.p, null, "This new system has expanded Fleeyo’s reach and has improved booking accuracy."), "\n", React.createElement(_components.p, null, "They have said a permanent goodbye to keeping and maintaining manual records and missed bookings."), "\n", React.createElement(_components.h3, null, "Driver application to streamline operations"), "\n", React.createElement(_components.p, null, "We also provided Fleeyo with a driver application to manage its fleet of drivers and partnered agents."), "\n", React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 800px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 62.5%; position: relative; bottom: 0; left: 0; background-image: url('data:image/webp;base64,UklGRqQAAABXRUJQVlA4IJgAAAAQBACdASoUAA0APtFUo0uoJKMhsAgBABoJZQDLLYxO3HLmOxGjV1JF4AD+iiERLEu1/rRBSrOlkIXfCjXG8DBtuwhxwfsHR1K2qu21+kuf+yGOYWmMvTMeWNJv77awlj+w+dZpFSDSoniAij6w1c5Nr7zsg773de5vN8ww0lyqlhdmLG/+d8piQkphMFS19uAQH1Z9YGCAAA=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"driver-application-to-streamline-operations\"\n        title=\"\"\n        data-src=\"/static/a161448cb2fb9f64ba1c279ca00d70a1/cf465/driver-application-to-streamline-operations.webp\"\n        data-srcset=\"/static/a161448cb2fb9f64ba1c279ca00d70a1/a5e6d/driver-application-to-streamline-operations.webp 200w,\n/static/a161448cb2fb9f64ba1c279ca00d70a1/2276a/driver-application-to-streamline-operations.webp 400w,\n/static/a161448cb2fb9f64ba1c279ca00d70a1/cf465/driver-application-to-streamline-operations.webp 800w\"\n        sizes=\"(max-width: 800px) 100vw, 800px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }), "\n", React.createElement(_components.p, null, "With the app, drivers can confirm their availability and accept rides in real-time."), "\n", React.createElement(_components.p, null, "This removed the need for back-and-forth calls and reduced delays in assigning trips."), "\n", React.createElement(_components.p, null, "Fleeyo can now track every driver’s status and assign rides instantly, ensuring quicker service for passengers and an organized fleet operation."), "\n", React.createElement(_components.h3, null, "Digital platform for a streamlined experience"), "\n", React.createElement(_components.p, null, "With Yelowsoft’s digital platform, Fleeyo moved all its offline operations online."), "\n", React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 800px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 62.5%; position: relative; bottom: 0; left: 0; background-image: url('data:image/webp;base64,UklGRmgAAABXRUJQVlA4IFwAAACwAwCdASoUAA0APtFapEwoJSOiMAgBABoJaQAAW+s78n41+5N8AADOP3XBbJeNARb2ztFdax4C7Ongb9aEIpfvR3/5mf/nP4K30//ssElBvctyjjNmEqlUHPoAAA=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"digital-platform-for-a-streamlined-experience\"\n        title=\"\"\n        data-src=\"/static/c2bd9907fccab1dd127c572895f84ae6/cf465/digital-platform-for-a-streamlined-experience.webp\"\n        data-srcset=\"/static/c2bd9907fccab1dd127c572895f84ae6/a5e6d/digital-platform-for-a-streamlined-experience.webp 200w,\n/static/c2bd9907fccab1dd127c572895f84ae6/2276a/digital-platform-for-a-streamlined-experience.webp 400w,\n/static/c2bd9907fccab1dd127c572895f84ae6/cf465/digital-platform-for-a-streamlined-experience.webp 800w\"\n        sizes=\"(max-width: 800px) 100vw, 800px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }), "\n", React.createElement(_components.p, null, "Fleeyo’s admin can now manage everything from booking confirmation to trip assignments in one place."), "\n", React.createElement(_components.p, null, "They can also add multiple dispatchers to assign trips in real time. It prevents delays and ensures customers get their rides promptly."), "\n", React.createElement(_components.p, null, "The once scattered and time-consuming process is now a well-oiled machine."), "\n", React.createElement(_components.p, null, "Fleeyo is thanking Yelowsoft for its reliable taxi dispatch software for airport transfers."), "\n", React.createElement(_components.h2, {
    id: "results-fleeyo-got"
  }, "Results Fleeyo got"), "\n", React.createElement(_components.p, null, "The transformation was remarkable, and the numbers speak for themselves."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Fleeyo saw a ", React.createElement(_components.strong, null, "65%"), " increase in international bookings."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "50%"), " boost in overall trip volume."), "\n", React.createElement(_components.li, null, "Achieve a ", React.createElement(_components.strong, null, "70%"), " faster booking confirmation rate"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "98%"), " customer satisfaction due to quicker and smoother operations"), "\n", React.createElement(_components.li, null, "Fleeyo increased its operational capacity by ", React.createElement(_components.strong, null, "40%")), "\n"), "\n", React.createElement(_components.h2, {
    id: "what-does-fleeyo-do-to-get-these-results"
  }, "What does Fleeyo do to get these results?"), "\n", React.createElement(_components.p, null, "Here’s how Fleeyo used Yelowsoft’s solution to get ahead:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Integrated a global ", React.createElement(_components.a, {
    href: "/web-based-taxi-booking-system"
  }, "web booking platform"), " to make booking easy for customers from anywhere"), "\n", React.createElement(_components.li, null, "Empowered drivers with a dedicated app to accept rides and confirm availability instantly"), "\n", React.createElement(_components.li, null, "Digitized all their operations to ensure smooth communication between dispatchers, drivers, and passengers"), "\n", React.createElement(_components.li, null, "Streamlined the booking process, saving time and reducing manual errors"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
